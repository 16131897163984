import { Component, OnInit } from '@angular/core';
import { CommonService } from '@shared/services/common.service';
import { RestService } from 'app/rest.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-creategallery',
  templateUrl: './creategallery.component.html',
  styleUrls: ['./creategallery.component.scss']
})
export class CreategalleryComponent implements OnInit {
  formdata:any;
  createalbumdata:any;
  create_cat_data:any;
  get_album_data:any;
  categorydata:any;
  catData:any;
  albumdata:any;
  albData:any;
  galData:any;
  galResponse:any;
  myFiles:string [] = [];
  submitResponse:any;
  submitData:any;
  userdata:any;
  closeResult:any;
  constructor(public cmnSrv: CommonService , public modalService: NgbModal, public rest: RestService, public router: Router, public toastr: ToastrService, public loadingBar: LoadingBarService) {
    this.formdata = {
      "gal_name":"",
      "gt_id":"",
      "gal_alb_id":"",
      "gal_cat_id":""
    };
    this.createalbumdata={
     "gal_type":"",
     "album_name":""
    };
    this.create_cat_data={
     "type_id":"",
     "album_id":"",
     "category_name":""
    };
    this.userdata = JSON.parse(localStorage.getItem('userData'));
   }
  ngOnInit() {

    this.getgaltype();
  }
  
  getCategory(event : any) {
    
    var category_data={
      "album_id":event.target.value,
     };
    this.loadingBar.start();  
    this.rest.getcategory(category_data).subscribe((data: {}) => {
      this.categorydata = data;
      if (this.categorydata.status === true) {
        this.catData = this.categorydata.result;
        this.loadingBar.complete();
      } else {
        this.loadingBar.complete();
      }
    });
  }
  //
  getAlbum(event : any) {
    
    var album_data={
      "album_id":event.target.value,
     }; 
    this.loadingBar.start();  
    this.rest.getalbum(album_data).subscribe((data: {}) => {
      this.albumdata = data;
      if (this.albumdata.status === true) {
        this.albData = this.albumdata.result;        
        this.loadingBar.complete();
      } else {
        this.loadingBar.complete();
      }
    });
  }
  getgaltype() {
    this.loadingBar.start();  
    this.rest.getgaltype({}).subscribe((data: {}) => {
      this.galResponse = data;
      if (this.galResponse.status === true) {
        this.galData = this.galResponse.result;        
        this.loadingBar.complete();
      } else {
        this.loadingBar.complete();
      }
    });
  }
  getFileDetails (e) {    
    for (var i = 0; i < e.target.files.length; i++) { 
      this.myFiles.push(e.target.files[i]);
    }         
  }

  processFile(gal_id) {   
    const frmData = new FormData();
    var fileLen = this.myFiles.length;
    for (var i = 0; i < this.myFiles.length; i++) { 
      frmData.append("filename", this.myFiles[i]);      
    }      
    if(fileLen > 1)
       this.rest.multiGalleryImage(frmData,this.formdata,gal_id).subscribe((res) => {
         this.formdata = {
          "gal_name":"",
          "gt_id":"",
          "gal_alb_id":"",
          "gal_cat_id":""
         };
         this.toastr.success("Gallery created successfully.");
       },(err) => {
        this.formdata = {
          "gal_name":"",
          "gt_id":"",
          "gal_alb_id":"",
          "gal_cat_id":""
         };
         this.toastr.success("Please try again.");
       });
    else
      this.rest.singleGalleryImage(frmData,this.formdata,gal_id).subscribe((data) => {
      
      this.submitResponse = data;
      if (this.submitResponse.status === true) {
        this.submitData = this.submitResponse.result;      
        this.toastr.success("Album created successfully.");
        this.formdata = {
          "gal_name":"",
          "gt_id":"",
          "gal_alb_id":"",
          "gal_cat_id":""
         };
        this.loadingBar.complete();
      } else {   
        this.toastr.error("Please try again.");    
        this.formdata = {
          "gal_name":"",
          "gt_id":"",
          "gal_alb_id":"",
          "gal_cat_id":""
         }; 
        this.loadingBar.complete();
      }

      },(err) => {});   
  }
  submitForm(){         
    this.formdata.gal_type = this.formdata.gt_id;
    this.formdata.gal_added_by = this.userdata.admin_id;
    console.log(this.formdata);
    this.loadingBar.start();  
    this.rest.creategallery(this.formdata).subscribe((data: {}) => {
      this.submitResponse = data;
      if (this.submitResponse.status === true) {
        this.submitData = this.submitResponse.result;    
        this.processFile(this.submitData);    
        this.loadingBar.complete();
      } else {        
        this.loadingBar.complete();
      }
    });
  }
  

  createGalleryType()
  {
    var gallery_data = {
      "gal_name":this.formdata.gal_name,
      "gal_added_by":this.userdata.admin_id
    };
    this.loadingBar.start(); 
    this.rest.createGalleryType(gallery_data).subscribe((data: {}) => {
    this.submitResponse = data;
      if (this.submitResponse.status === true) {
        this.submitData = this.submitResponse.result;      
        this.toastr.success("Gallery Type created successfully.");
        this.loadingBar.complete();
        this.getgaltype();
      } else {   
        this.toastr.error("Please try again.");     
        this.loadingBar.complete();
      }
    });
  }

  createGalleryAlbum()
  {
    this.createalbumdata.gal_type=this.createalbumdata.gal_type;
    this.createalbumdata.album_name=this.createalbumdata.album_name;
    this.loadingBar.start(); 
    this.rest.createGalleryAlbum(this.createalbumdata).subscribe((data: {}) => {
    this.submitResponse = data;
      if (this.submitResponse.status === true) {
        this.submitData = this.submitResponse.result;      
        this.toastr.success("Album created successfully.");
        this.loadingBar.complete();
      } else {   
        this.toastr.error("Please try again.");     
        this.loadingBar.complete();
      }
    });
  }

  createGalleryCategory()
  {
    this.create_cat_data.type_id=this.create_cat_data.type_id;
    this.create_cat_data.album_id=this.create_cat_data.album_id;
    this.create_cat_data.category_name=this.create_cat_data.category_name;
    this.loadingBar.start(); 
    this.rest.createGalleryCategory(this.create_cat_data).subscribe((data: {}) => {
    this.submitResponse = data;
      if (this.submitResponse.status === true) {
        this.submitData = this.submitResponse.result;      
        this.toastr.success("Category created successfully.");
        this.loadingBar.complete();
      } else {   
        this.toastr.error("Please try again.");     
        this.loadingBar.complete();
      }
    });
  }

  gat_album_data(event : any){

    this.loadingBar.start();
    var album_data={
     "album_id":event.target.value
    }; 
    this.rest.gat_album_data(album_data).subscribe((data: {}) => {
      this.galResponse = data;
      if (this.galResponse.status === true) {
        this.get_album_data = this.galResponse.result;    
        console.log(JSON.stringify(this.get_album_data));    
        this.loadingBar.complete();
      } else {
        this.loadingBar.complete();
      }
    });
    
  }




  createoperations(){
    //createoperations
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {      
      this.closeResult = `Closed with: ${result}`;        
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  gotoGallery()
  {
    
    this.router.navigateByUrl('/gallerylist');
  }
}
