
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestService } from '../../rest.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CompleterService, CompleterData } from 'ng2-completer';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  toggleFormClass;
  stripeTest: FormGroup;
  loginData: any;
  apiResponse: any;
  userData:any;
  loginstatus :any;
  constructor(public rest: RestService, private fb: FormBuilder, public router: Router, public toastr: ToastrService, public loadingBar: LoadingBarService, public completerService: CompleterService) { 
    this.loginData = {
      'contact_email': '',
      'password': ''
    };
    this.loginstatus = localStorage.getItem('loginstatus');
  }
  ngOnInit() {   
    if(this.loginstatus==1){
      this.router.navigateByUrl('/home');
    }
  }
  dologin() {    
    this.loadingBar.start();
    if(this.loginData.contact_email==''){
      this.loadingBar.complete();
      this.toastr.error("Please enter username or mobile number.");
    }else if(this.loginData.password==''){
      this.loadingBar.complete();
      this.toastr.error("Please enter password.");
    }else{    
    this.rest.dologin(this.loginData).subscribe((data: {}) => {
      this.apiResponse = data;      
      if (this.apiResponse.success === true) {
        this.userData = this.apiResponse.data;
        localStorage.setItem('accesstoken', this.apiResponse.token);
        localStorage.setItem('jwtid', this.apiResponse.jwtid);
        localStorage.setItem('userData', JSON.stringify(this.apiResponse.data));
        localStorage.setItem('loginstatus', '1');
        this.loadingBar.complete();
        this.router.navigateByUrl('/home');                
      } else {
        this.userData = '';
        this.toastr.error('Invalid Credentials');
        this.loadingBar.complete();
      }
    });
    }
  }
}
