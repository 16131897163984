import { Component } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  menuChar: number;
  sidebarItems: any;
  userRole: any;
  userData: any;
  loginstatus: any;
  constructor(public cmnSrv: CommonService,public router: Router) {
    this.loginstatus = localStorage.getItem('loginstatus');        
    this.userData = JSON.parse(localStorage.getItem('userData'));    
  }
  openPage(page){
    if(this.loginstatus == 1){
      this.router.navigateByUrl(page);
    }else{
      this.router.navigateByUrl('/');
    }
  }
  openRouter(){
    if(this.loginstatus == 1){
      this.router.navigateByUrl('/home');
    }else{
      this.router.navigateByUrl('/');
    }
  }
  logoutuser(){    
    localStorage.removeItem('Role');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('jwtid');
    localStorage.removeItem('loginstatus');
    localStorage.removeItem('userData');
    this.router.navigateByUrl('/');
  }
}
