import { Component, OnInit } from '@angular/core';
import { CommonService } from '@shared/services/common.service';
import { RestService } from 'app/rest.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-smart',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  userData: any;
  userRes: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loginstatus : any;
  constructor(public cmnSrv: CommonService , public rest: RestService, public router: Router, public toastr: ToastrService, public loadingBar: LoadingBarService) {
    this.loginstatus = localStorage.getItem('loginstatus');     
   }
  ngOnInit() {
    if(this.loginstatus != 1){
      this.router.navigateByUrl('/');
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getUsers();
  }
  getUsers() {
    this.loadingBar.start();
    this.rest.getadminlist({}).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.status === true) {
        this.userData = this.userRes.result;
        this.loadingBar.complete();
        this.dtTrigger.next();
      } else {
        this.userData = '';
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }
}
