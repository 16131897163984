import { Component, OnInit } from '@angular/core';
import { CommonService } from '@shared/services/common.service';
import { RestService } from 'app/rest.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-smart',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  userData: any;
  userRes: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loginstatus: any;
  constructor(public cmnSrv: CommonService , public rest: RestService, public router: Router, public toastr: ToastrService, public loadingBar: LoadingBarService) { 
    this.loginstatus = localStorage.getItem('loginstatus');
  }
  ngOnInit() {
    if(this.loginstatus != 1){
      this.router.navigateByUrl('/');
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getUsers(1);
  }
  getUsers(int) {
    this.loadingBar.start();
    var sendparams = {
      "gal_id":""
    };
    this.rest.getusergallery(sendparams).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.status === true) {
        this.userData = this.userRes.result;
        this.loadingBar.complete();
        if(int==1){
          this.dtTrigger.next();
        } 
      } else {
        this.userData = '';
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }
  createGallery(){
    this.router.navigateByUrl('/creategallery'); 
  }
  getDetails(id){
    this.router.navigate(['/gallerydetails', id]);
  }

  updateGalleryStatus()
  {
    this.router.navigateByUrl('/creategallery');
  }


  updateStatus(data,status){
    
    var newParams = {
      "userid":data.gal_id,
      "user_status":status,
      "tablename":"gallery"
    }; 
    
    this.loadingBar.start();
    this.rest.galleryupdateStatus(newParams).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.code === 200) {
        this.getUsers(2);
        this.loadingBar.complete();
        this.toastr.success("Status updated successfully");       
      } else {
        this.userData = '';
        this.toastr.success("Please try again.");
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }

  deleteActions(id){
    if(confirm("Are you sure ?")) {

    var newParams = {
      "row_id":id,
    }; 
    this.loadingBar.start();
    this.rest.deleteGallery(newParams).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.code === 200) {
        this.getUsers(2);
        this.loadingBar.complete();
        this.toastr.success("Deleted successfully");       
      } else {
        this.userData = '';
        this.toastr.success("Please try again.");
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
    }
  }

}
