import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { UserlistComponent } from './userlist/userlist.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AdminsComponent } from './admins/admins.component';
import { CreategalleryComponent } from './creategallery/creategallery.component';
import { GallerydetailsComponent } from './gallerydetails/gallerydetails.component';

const routes: Routes = [
  // { path: '', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  // { path: 'pages', loadChildren: './pages/pages.module#PagesModule' }
  { path: '', component: AuthComponent },
  { path: 'home', component: DashboardComponent },
  { path: 'userlist', component: UserlistComponent },
  { path: 'gallerylist', component: GalleryComponent },
  { path: 'adminlist', component: AdminsComponent },
  { path: 'creategallery', component: CreategalleryComponent },
  { path: 'gallerydetails/:id', component: GallerydetailsComponent },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
