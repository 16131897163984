import { Component, OnInit } from '@angular/core';
import { CommonService } from '@shared/services/common.service';
import { RestService } from 'app/rest.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-smart',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  userData: any;
  userRes: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  userUpdate:any;
  loginstatus: any;
  authToken: any;
  closeResult:any;
  singledata:any;
  sendStatus:any;
  imagepost = 'https://talkbloxapi.udaantechnologies.com/';
  constructor(public cmnSrv: CommonService , public rest: RestService, public modalService: NgbModal,public router: Router, public toastr: ToastrService, public loadingBar: LoadingBarService) { 
    this.loginstatus = localStorage.getItem('loginstatus');     
    this.authToken = localStorage.getItem('accesstoken');
  }
  ngOnInit() {
    if(this.loginstatus != 1){
      this.router.navigateByUrl('/');
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getUsers(1);
  }
  getUsers(int) {
    this.loadingBar.start();
    var params = { "authorization":this.authToken };
    this.rest.getuserlist({}).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.status === true) {        
        this.userData = this.userRes.result;        
        this.loadingBar.complete();
        if(int==1){
          this.dtTrigger.next();
        }        
      } else {
        this.userData = '';
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }
  updateStatus(data,status){
    var newParams = {
      "userid":data.userid,
      "user_status":status,
      "tablename":"user_data"
    };         
    this.loadingBar.start();
    this.rest.updateStatus(newParams).subscribe((data: {}) => {
      this.userUpdate = data;
      if (this.userUpdate.code === 200) {
        this.getUsers(2);
        this.loadingBar.complete();
        this.toastr.success("Status updated successfully");       
      } else {
        this.userData = '';
        this.getUsers(2);
        this.toastr.success("Please try again.");
        this.toastr.error(this.userUpdate.errMessage);
        this.loadingBar.complete();
      }
    });
  }
  actions(str){
    console.log(str);
  }

  showsingledata(data)
  { 
   this.singledata=data;
  }



  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {      
      this.closeResult = `Closed with: ${result}`;        
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  sendBloxMessage(data) {
    var newParams = {
      "userid": data.userid
    };         
    this.loadingBar.start();
    this.rest.sendBlox(newParams).subscribe((data: {}) => {
      this.sendStatus = data;
      if (this.sendStatus.header.ErrorCode === 200) {
        this.loadingBar.complete();
        this.toastr.success("Message sent successfully");       
      } else {        
        this.toastr.success("Please try again.");
        this.toastr.error(this.sendStatus.header.ErrorMessage);
        this.loadingBar.complete();
      }
    });
  }


}
