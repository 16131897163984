import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  endpoint = 'https://api.talkblox.info/api/v1/';
  imagepost = 'https://api.talkblox.info/';
  httpOptions: any;
  body: any;
  authToken: any;
  constructor(private http: HttpClient) {
    if (localStorage.getItem('loginstatus') === '1') {     
       this.authToken = localStorage.getItem('accesstoken');
      // console.log("authToken",this.authToken);
      // this.httpOptions = {
      //   headers: new HttpHeaders({          
      //     'authorization': 'Bearer '+this.authToken.authToken
      //   })
      // };
      // console.log("header options",this.httpOptions);
    }
  }
  private extractData(res: Response) {
    this.body = res;
    return this.body || {};
  }
  dologin (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'adminlogin', params, this.httpOptions ).pipe(
      tap((dologin) => console.log()),
      catchError(this.handleError<any>('dologin'))
    );
  }
  getuserlist (params): Observable<any> {    
    return this.http.post<any>(this.endpoint + 'userlist', params, this.httpOptions ).pipe(
      tap((getuserlist) => console.log()),
      catchError(this.handleError<any>('getuserlist'))
    );
  }
  getadminlist (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'adminlist', params, this.httpOptions ).pipe(
      tap((getadminlist) => console.log()),
      catchError(this.handleError<any>('getadminlist'))
    );
  }
  getcategory (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'categoryadmin', params, this.httpOptions ).pipe(
      tap((getcategory) => console.log()),
      catchError(this.handleError<any>('getcategory'))
    );
  }
  getalbum (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'album', params, this.httpOptions ).pipe(
      tap((getalbum) => console.log()),
      catchError(this.handleError<any>('getalbum'))
    );
  }
  getusergallery (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'usergallery', params, this.httpOptions ).pipe(
      tap((getusergallery) => console.log()),
      catchError(this.handleError<any>('getusergallery'))
    );
  }
  getgaltype (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'getgaltype', params, this.httpOptions ).pipe(
      tap((getgaltype) => console.log()),
      catchError(this.handleError<any>('getgaltype'))
    );
  }
  
  //updateStatus
  updateStatus (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'updateStatus', params, this.httpOptions ).pipe(
      tap((updateStatus) => console.log()),
      catchError(this.handleError<any>('updateStatus'))
    );
  }
  creategallery (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'creategallery', params, this.httpOptions ).pipe(
      tap((creategallery) => console.log()),
      catchError(this.handleError<any>('creategallery'))
    );
  }
  
  singleGalleryImage (image,params,gal_id): Observable<any> {        
    const frmData = new FormData();    
    image.append("gal_name",params.gal_name);  
    image.append("gt_id",params.gt_id);  
    image.append("gal_alb_id",params.gal_alb_id);  
    image.append("gal_cat_id",params.gal_cat_id); 
    image.append("gal_id",gal_id);  
    return this.http.post<any>(this.imagepost + 'uploadgallerysingle', image, this.httpOptions ).pipe(
      tap((singleGalleryImage) => console.log()),
      catchError(this.handleError<any>('singleGalleryImage'))
    );
  }
  
  multiGalleryImage (image,params,gal_id): Observable<any> {       
    const frmData = new FormData();
    image.append("gal_name",params.gal_name);  
    image.append("gt_id",params.gt_id);  
    image.append("gal_alb_id",params.gal_alb_id);  
    image.append("gal_cat_id",params.gal_cat_id); 
    image.append("gal_id",gal_id);       
    return this.http.post<any>(this.imagepost + 'uploadgallery', image, this.httpOptions ).pipe(
      tap((multiGalleryImage) => console.log()),
      catchError(this.handleError<any>('multiGalleryImage'))
    );
  }

  createoperations (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'createoperations', params, this.httpOptions ).pipe(
      tap((createoperations) => console.log()),
      catchError(this.handleError<any>('createoperations'))
    );
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

 /* work by pj */
 
 createGalleryType (params): Observable<any> {
  return this.http.post<any>(this.endpoint + 'createGalleryType', params, this.httpOptions ).pipe(
    tap((createGalleryType) => console.log()),
    catchError(this.handleError<any>('createGalleryType'))
  );
}

createGalleryAlbum (params): Observable<any> {
  return this.http.post<any>(this.endpoint + 'createGalleryAlbum', params, this.httpOptions ).pipe(
    tap((createGalleryType) => console.log()),
    catchError(this.handleError<any>('createGalleryAlbum'))
  );
}

createGalleryCategory (params): Observable<any> {
  return this.http.post<any>(this.endpoint + 'createGalleryCategory', params, this.httpOptions ).pipe(
    tap((createGalleryType) => console.log()),
    catchError(this.handleError<any>('createGalleryCategory'))
  );
}

gat_album_data (params): Observable<any> {
  return this.http.post<any>(this.endpoint + 'gat_album_data', params, this.httpOptions ).pipe(
    tap((createGalleryType) => console.log()),
    catchError(this.handleError<any>('gat_album_data'))
  );
}

galleryupdateStatus (params): Observable<any> {
  return this.http.post<any>(this.endpoint + 'galleryupdateStatus', params, this.httpOptions ).pipe(
    tap((updateStatus) => console.log()),
    catchError(this.handleError<any>('updateStatus'))
  );
}

deleteGallery (params): Observable<any> {
  return this.http.post<any>(this.endpoint + 'deleteGallery', params, this.httpOptions ).pipe(
    tap((updateStatus) => console.log()),
    catchError(this.handleError<any>('updateStatus'))
  );
}

//sendBlox
  sendBlox (params): Observable<any> {
    return this.http.post<any>(this.endpoint + 'sendBloxCastToSubscriberByAdmin', params, this.httpOptions ).pipe(
      tap((sendBlox) => console.log()),
      catchError(this.handleError<any>('sendBlox'))
    );
  }

}
