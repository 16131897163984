import { Component } from '@angular/core';
import { CommonService } from '@shared/services/common.service';
import {MatMenuModule} from '@angular/material/menu';
import { Router } from '@angular/router';
import { RestService } from 'app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
  adminRole: any;
  ticketRes: any;
  ticketResData: any;
  userdata: any;
  ticketdata = {
    "subject":"",
    "discription":"",
    "fbo_id":""
  }
  statusRes:any;
  statusData:any;
  showAlert:any;
  totalLen:any;
  adminName:any;
  statusUpData:any;
  statusUpRes:any;
  interval: any;
  constructor(public cmnSrv: CommonService,  public router: Router,public rest: RestService, public toastr: ToastrService, public loadingBar: LoadingBarService) { 
    this.adminRole = localStorage.getItem('Role');
    this.showAlert = 0;
    this.userdata = JSON.parse(localStorage.getItem('userData'));  
    //this.adminName = this.userdata[0].fbo_name;  
    this.totalLen = 0;
  }
  ngOnInit(){
  }
  playSound(){
    var audio = new Audio('assets/to-the-point.mp3');
    audio.play();
    // if(this.totalLen>0){
    //   this.interval = setInterval(() => {
    //     var audio = new Audio('assets/to-the-point.mp3');
    //     audio.play();
    //   }, 5000);
    // }
  }
  logoutuser() {
    localStorage.removeItem('Role');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('jwtid');
    localStorage.removeItem('loginstatus');
    localStorage.removeItem('userData');
    this.router.navigateByUrl('/');
  }
  account() {
    this.router.navigateByUrl('/settings');
  }
  terms() {
    this.router.navigateByUrl('/termconditions');    
  }
 
}
