import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RestService } from 'app/rest.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-gallerydetails',
  templateUrl: './gallerydetails.component.html',
  styleUrls: ['./gallerydetails.component.scss']
})
export class GallerydetailsComponent implements OnInit {
  galleryId: any;
  userData: any;
  userRes: any;
  imagepost = 'https://api.talkblox.info/';
  dtTrigger: Subject<any> = new Subject();
  loginstatus: any;
  constructor(private route: ActivatedRoute,public router: Router, public toastr: ToastrService, public loadingBar: LoadingBarService, public rest: RestService) {
   
    this.loginstatus = localStorage.getItem('loginstatus');

   }

  ngOnInit() {
    this.galleryId = this.route.snapshot.paramMap.get("id");
    this.getUsers();
  }

  getUsers() {
    this.loadingBar.start();
    var sendparams = {
      "gal_id": this.galleryId
    };
    this.rest.getusergallery(sendparams).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.status === true) {
        this.userData = this.userRes.result;
        console.log( this.userData);
        this.loadingBar.complete();
        this.dtTrigger.next();
      } else {
        this.userData = '';
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }

  gotoGallery()
  {
    
    this.router.navigateByUrl('/gallerylist');
  }

}
