import { Component, OnInit } from '@angular/core';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-smart',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  constructor(public cmnSrv: CommonService) { }

  ngOnInit() {
  }

}
