import { Component, OnInit, Renderer2, AfterViewChecked } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd } from '@angular/router';
import { CommonService } from '@shared/services/common.service';
import * as NProgress from 'nprogress';
import { RestService } from 'app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataTablesModule } from 'angular-datatables';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, AfterViewChecked {
  loginstatus:any;
  galRes:any;
  gallerydata: any;
  userData: any;
  userRes: any;
  authToken: any;
  constructor(private router: Router, public cmnSrv: CommonService, private renderer: Renderer2,public loadingBar: LoadingBarService,public rest: RestService, public toastr: ToastrService) {
    this.loginstatus = localStorage.getItem('loginstatus');     
    this.authToken = localStorage.getItem('accesstoken');
    NProgress.configure({ showSpinner: false });
    /**
     * to add preload class before loading the APP
     * to prevent transition effection on many element on app startup
     */
    this.renderer.addClass(document.body, 'preload');
    this.getGallery();
    this.getUsers();
  }
  openPage(page){
    this.router.navigateByUrl(page);
  }
  ngOnInit() {
    if(this.loginstatus != 1){
      this.router.navigateByUrl('/');
    }
    /**
     * for progres bar (loading) on top; after route chages
     */
    this.router.events.subscribe((obj: any) => {
      if (obj instanceof RouteConfigLoadStart) {
        NProgress.start();
        NProgress.set(0.4);
      } else if (obj instanceof RouteConfigLoadEnd) {
        NProgress.set(0.9);
        setTimeout(() => {
          NProgress.done();
          NProgress.remove();
        }, 500);
      } else if (obj instanceof NavigationEnd) {
        this.cmnSrv.dashboardState.navbarToggle = false;
        this.cmnSrv.dashboardState.sidebarToggle = true;
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewChecked() {
    /**
     * for removing transition effection on load of the page
     */
    setTimeout(() => {
      this.renderer.removeClass(document.body, 'preload');
    }, 300);
  }

  getGallery() {
    this.loadingBar.start();
    var sendparams = {
      "gal_id":""
    };
    this.rest.getusergallery(sendparams).subscribe((data: {}) => {
      this.galRes = data;
      if (this.galRes.status === true) {
        this.gallerydata = this.galRes.result;
        this.loadingBar.complete();
      } else {
        this.gallerydata = '';
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }


  getUsers() {
    this.loadingBar.start();
    var params = { "authorization":this.authToken };
    this.rest.getuserlist({}).subscribe((data: {}) => {
      this.userRes = data;
      if (this.userRes.status === true) {        
        this.userData = this.userRes.result;       
        this.loadingBar.complete();       
      } else {
        this.userData = '';
        this.toastr.error(this.userRes.errMessage);
        this.loadingBar.complete();
      }
    });
  }

}
