import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent } from './pages/auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import {MatMenuModule} from '@angular/material/menu';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule, MatInputModule, MatRadioModule, MatSelectModule, MatDatepickerModule } from '@angular/material';
import { DataTablesModule } from 'angular-datatables';
import { Ng2CompleterModule } from "ng2-completer";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BasicModal } from './modal/basicmodal';
import { PopupComponent } from './popup/popup.component';
import { UserlistComponent } from './userlist/userlist.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AdminsComponent } from './admins/admins.component';
import { CreategalleryComponent } from './creategallery/creategallery.component';
import { GallerydetailsComponent } from './gallerydetails/gallerydetails.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    DashboardComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    BasicModal,
    PopupComponent,
    UserlistComponent,
    GalleryComponent,
    AdminsComponent,
    CreategalleryComponent,
    GallerydetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatMenuModule,
    NgxStripeModule.forRoot('pk_test_JYV3epbInMhufWnjpzSMPnFW'),
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    LoadingBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    DataTablesModule,
    Ng2CompleterModule,
    MatSelectModule,
    MatDatepickerModule,
    NgbModule,
    NgSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

